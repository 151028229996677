<div class="promotion-mobile-container">
  <div class="header-container">
    <fa-icon [icon]="faArrowLeft" class="back-icon" (click)="goBack()"></fa-icon>
    <div class="title">REFERRAL</div>
  </div>

  @if(part === 'default') {
    <div class="promotion-body">
      <div class="promotion-content">
        @if (islogin) {
        <div class="referral-container w-100" (click)="copyReferralCode((getReferralPlayer?.referral_code))">
          <div class="referral-code">{{getReferralPlayer?.referral_code}}</div>
          <div class="subtitle">Klik untuk menyalin kode referral</div>
        </div>
      } @else {
        <div class="referral-container w-100" [routerLink]="['/profile']">
          <div class="referral-code">Mohon Login</div>
          <div class="subtitle login-text" >Masuk</div>
        </div>
      }
      @if (islogin) {
        <div class="referral-code-container">
          <div class="content-left">
            <div class="text">
              URL Kode Referral
            </div>
            <div class="url">
              {{baseUrl}}/register?ref={{getReferralPlayer?.referral_code}}
            </div>
          </div>
          <div class="content-right">
            <div class="button-copy" (click)="copyReferral((baseUrl+'/register?ref='+getReferralPlayer?.referral_code))">
              SALIN
            </div>
          </div>
        </div>
        <div class="referral-info-container">
          <div class="info-item first">
            <div class="title">Total Keseluruhan</div>
            <div class="value">{{IDRFormat((getReferralPlayer?.earning).toString())}}</div>
          </div>
          <div class="info-item second">
            <div class="title">Total Diklaim</div>
            <div class="value">{{IDRFormat((getReferralPlayer?.claimed_earning).toString())}}</div>
          </div>
          <div class="info-item third">
            <div class="title">Total Penghasilan</div>
            <div class="value">{{IDRFormat((getReferralPlayer?.earning  - getReferralPlayer?.claimed_earning).toString()) }}</div>
            <div class="btn-info" (click)="toggleReferralClaim()">Claim</div>
          </div>
          <div class="info-item fourth">
            <div class="title">Total Downline</div>
            <div class="value">{{getReferralPlayer?.total_downline}}</div>
            <div class="btn-info" (click)="toggleDownline()">Detail</div>
          </div>
        </div>
      }

        @if(referralRule.hide_rules == false) {
          @if (referralRule.calculate_by_turnover || referralRule.calculate_by_win_lose) {
            <table class="referral-rules-table table-responsive-promotion">
              <thead>
                <tr>
                  <th>Tipe Permainan</th>
                  @if(referralRule.calculate_by_turnover){
                    <th >Rollingan</th>
                  }
                  @if(referralRule.calculate_by_win_lose){
                    <th >Menang/Kalah</th>
                  }
                </tr>
              </thead>
              <tbody>
                @for(item of tableRule | keyvalue ; track item ){
                  <tr>
                    <td>{{item.value['game_type']}}</td>
                    @if(referralRule.calculate_by_turnover){
                      <td >{{item.value['turnover_percentage']}}%</td>
                    }
                    @if(referralRule.calculate_by_win_lose){
                      <td >{{item.value['winlose_percentage']}}%</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
            <div class="earning-rules">
              <span>** Sesuai Syarat Berikut ini:</span><br>
              <ol>
              @for(item of earningRules | keyvalue ; track item ){
                <li>{{earningRulesText[item.key]}} {{customIDRFormat(item.value)}}</li>
              }
            </ol>
              </div>
          }
        }
      </div>
    </div>
  }

  @if(part === 'claim') {
    <div class="promotion-body">
      <div class="promotion-content">
        <div class="referral-container w-100 bonus-referral">
          <div class="subtitle">Bonus Referral Tersedia</div>
          <div class="referral-code">{{IDRFormat((getReferralPlayer?.earning  - getReferralPlayer?.claimed_earning).toString()) }}</div>
        </div>
      </div>

      <div class="claim-wrapper">
        <div class="content-left container-input">
          <label for="inputAmount" class="label-input-small">Masukan Nominal</label>
          <input id="inputAmount" type="text" spellcheck="false" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" placeholder=" " [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')" (ngModelChange)="updateValueNominal($event)" name="inputAmount"
              aria-label="Masukan Nominal" />
        </div>
        <div class="content-right flex-area">
          <div class="btn-claim" (click)="setMaxClaim()">
            MAX
          </div>
          <div class="btn-claim" (click)="claim()">
            KLAIM
          </div>
        </div>
      </div>

      <div class="promotion-table history-table" id="scrollableTableHistory" (scroll)="onScrollHistory($event)">
        <div class="history-list-container mt-2">
          <div class="header">
            <div class="tanggal">Tanggal</div>
            <div class="jumlah">Jumlah Klaim</div>
          </div>

          @if(getHistory !== null){
            @if(lengthHistory != 0){
                @for (item of getHistory; track item;) {
                  <div class="history-item">
                    <div class="tanggal"><img src="../../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(item._id)"/>{{ dateFormat7(item.created_at)}} | {{hourFormat(item.created_at)}}</div>
                    <div class="jumlah">{{IDRFormat(item.claimed_amount)}}</div>
                  </div>
                }
            } @else {
              <div class="no-data-content">
                <img src="../../template/green/assets/images/mobile/no_data.webp" class="img-fluid no-data-icon"/>
                <div class="no-data-title">
                  No Data Available
                </div>
                <div class="no-data-subtitle">
                  There is no data to show you right now
                </div>
              </div>
            }

            <div class="d-flex justify-content-center align-content-center">
              @if(isLoadingHistory){
                <div class="spinner-border"></div>
              }
            </div>
          }
          </div>
    </div>
  </div>
  }

  @if(part === 'downline') {
    <div class="promotion-body">
      <div class="promotion-table downline-table" id="scrollableTable" (scroll)="onScrollDownline($event)">
        @if(getReferralDownline !== null){
          <div class="mt-2"></div>
          @if(getReferralDownline.length != 0){
            @for (item of getReferralDownline; track item;) {
              <div class="downline-list-container mt-2">
                <div class="list-header" [ngStyle]="!websiteJSONData['configuration']['hide_referral_statistic']? {}: {'border-radius': '10px'}">
                  <div class="text" [ngClass]="{
                    'negative-amount': item.balance < 0,
                    'positive-amount': item.balance > 0
                  }"><img src="../../template/green/assets/images/key.webp" class="img-fluid key-icon" (click)="copyToClipboard(item._id)"/>{{item.player_login_id}}</div>
                  <div class="date">{{ dateFormat(item.created_at) }}</div>
                </div>
                @if (!websiteJSONData['configuration']['hide_referral_statistic']) {
                  <div class="list-content">
                    <div class="list-item">
                      <dl>
                        <dt>Total Deposit</dt>
                        <dd><span class="colon">:</span>IDR {{IDRFormat(item.total_deposit)}}</dd>
  
                        <dt>Total Turnover</dt>
                        <dd><span class="colon">:</span><span>IDR {{IDRFormat(item.total_turnover)}}</span></dd>
  
                        <!-- <dt>Total Win/Lose</dt> -->
                        <!-- <dd><span class="colon">:</span>IDR {{IDRFormat(saldoCurrent(item.balance_before, item.balance))}}</dd> -->
  
                        <dt>Total Penghasilan</dt>
                        @if(item.parent_earning){
                          <dd><span class="colon">:</span>IDR {{ item.parent_earning === 0 ? '0':IDRFormat(item.parent_earning)}}</dd>
                        }@else{
                          <dd><span class="colon">:</span>IDR 0</dd>
                        }
                      </dl>
                    </div>
                  </div>
                }
              </div>
            }
            @if(pageIndexDownline+1 <= maxPageDownline){
              <div class="d-flex justify-content-center align-content-center">
                @if(isLoadingDownline){
                  <div class="spinner-border"></div>
                }
              </div>
            }
          } @else {
            <div class="no-data-content">
              <img src="../../template/green/assets/images/mobile/no_data.webp" class="img-fluid no-data-icon"/>
              <div class="no-data-title">
                No Data Available
              </div>
              <div class="no-data-subtitle">
                There is no data to show you right now
              </div>
            </div>
          }
        }
      </div>
    </div>
  }
</div>
